import React from "react";
import styled from "styled-components";
import Text from "../Text";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { colorTheme } from "../../../styles/defaultTheme";

export default function Banner(props) {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          marginTop: 80,
          backgroundColor: !props.inverted ? "white" : "black",
        }}
      >
        <StyledBanner
          inverted={props.inverted}
          white={props.white}
          navy={props.navy}
        >
          <Text
            style={{ textAlign: "center" }}
            variant="banner"
            inverted={props.inverted}
          >
            {props.bannerText}{" "}
            <span className="bold-span">{props.bannerBold} </span>
            <br className="mobile-break" />
            <span className="link-span">
              <BannerLink className="bannerlink" href={props.bannerLink}>
                {t("learnMoreBtn")}
              </BannerLink>
            </span>
          </Text>
        </StyledBanner>
        {/*<MaintenanceBanner>*/}
        {/*  <Text*/}
        {/*    style={{ textAlign: "center" }}*/}
        {/*    variant="banner"*/}
        {/*    inverted={false}*/}
        {/*  >*/}
        {/*    ⚠️ Planned maintenance starting 16:55 PM. Expect outages ⚠️*/}
        {/*  </Text>*/}
        {/*</MaintenanceBanner>*/}
      </div>
    </>
  );
}

const StyledBanner = styled.div`
  background: ${(props) =>
    !props.inverted
      ? props.white
        ? colorTheme.colors.neutral.neutral7
        : colorTheme.colors.neutral.neutral9
      : props.navy
      ? "#0b121e"
      : "rgba(18, 22, 25, 1)"};
  animation: ribbon-drop 0.8s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 16px;
  justify-content: center;
  &.banner-top {
    background: ${(props) =>
      !props.inverted ? "rgba(18, 22, 25, 0)" : "rgba(18, 22, 25, 0.75)"};
    color: ${(props) => (!props.inverted ? "white" : "black")};
    display: flex;
    justify-content: space-evenly;
    gap: 40px;
  }

  .bold-span {
    color: ${(props) =>
      props.inverted ? "white" : `${colorTheme.colors.neutral.black}`};
    font-weight: 500;
    :hover {
      text-decoration: none;
      color: ${(props) =>
        props.inverted ? "white" : `${colorTheme.colors.neutral.black}`};
    }
  }

  @media only screen and (min-width: 1024px) {
    .mobile-break {
      display: none;
    }
  }
`;

const MaintenanceBanner = styled.div`
  background: #dc3545;
  //background: #4bb543;
  width: 100%;
  padding: 4px;
  display: flex;
  text-align: center;
  justify-content: center;

  h1 {
    font-weight: 400;
    color: white;
    font-size: 0.8rem;
  }
`;

const BannerLink = styled(Link)`
  color: rgba(36, 138, 255, 1) !important;
  cursor: pointer;
  :hover {
    color: rgba(36, 138, 255, 0.85) !important;
    text-decoration: underline !important;
  }
`;
